export const colors = [
  "#FFFFFF",
  "#FF69B4",
  "#800080",
  "#FF0000",
  "#800000",
  "#FFFF00",
  "#808000",
  "#00FF00",
  "#008000",
  "#00FFFF",
  "#008080",
  "#0000FF",
  "#000080",
  "#C0C0C0",
  "#808080",
  "#000000",
  "#FFE4E1",
  "#FF1493",
  "#8A2BE2",
  "#DC143C",
  "#8B4513",
  "#FFD700",
  "#FFA500",
  "#32CD32",
  "#20B2AA",
  "#1E90FF",
  "#6495ED",
  "#40E0D0",
  "#483D8B",
  "#D2691E",
  "#2F4F4F",
  "#696969"
];

export const paletteBgColor = "#f7f7f7";
