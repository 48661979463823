import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { Main } from '../../pages/main/main';
import { DrawApp } from '../../pages/draw-app/draw-app'
import { MapPage } from '../../pages/map-page/map-page';
import { TextEdit } from '../../pages/text/text-edit';
import './app.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/draw" element={<DrawApp />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/text/edit" element={<TextEdit />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
