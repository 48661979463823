import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { format, sub } from 'date-fns'
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { API_BASE_URL } from '../../constants/path';
import { DATE_FORMAT } from '../../constants/common';
import { Map } from '../../components/map/map';
import { useSessionTimer } from '../../components/hooks/use-session-timer';
import './map-page.css';

export function MapPage() {
  const [sessionData, error] = useSessionTimer();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isDateRangeActive, setIsDateRangeActive] = useState(true);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: sub(new Date(), { days: 1 }),
      endDate: new Date(),
      key: 'selection',
    }
  ]);
  const [searchString, setSearchString] = useState('');

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const startDate = format(dateRangeState[0].startDate, DATE_FORMAT);
      const endDate = format(dateRangeState[0].endDate, DATE_FORMAT);
      const response = await fetch(`${API_BASE_URL}/logs?start_date=${startDate}&end_date=${endDate}&q=${searchString}`);
      const jsonData = await response.json();
      setData(jsonData);
      return jsonData;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchDataDebounced = useDebouncedCallback(fetchData, 200);
  
  const onDateRangeChange = (item) => {
    setDateRangeState([item.selection]);
  };

  const onMapClick = () => {
    setIsDateRangeActive(false);
  };

  const onChangeSearchString = (event) => {
    setSearchString(event.target.value);
  }

  useEffect(() => {
    fetchData();
  }, [dateRangeState]);
  
  useEffect(() => {
    fetchDataDebounced();
  }, [searchString]);
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="map-page_container">
      <div className="map-page_controls-container">
        <div className='map-page_input-container'>
          <input
            value={searchString}
            onChange={onChangeSearchString}
            placeholder='Поиск'
          ></input>
        </div>
        <div
          className={cn('map-page_date-range-container', {
            'map-page_date-range-container--active': isDateRangeActive
          })}
          onMouseOver={() => setIsDateRangeActive(true)}
          onMouseOut={() => setIsDateRangeActive(false)}
        >
          <DateRange
            editableDateInputs={true}
            onChange={onDateRangeChange}
            moveRangeOnFirstSelection={false}
            ranges={dateRangeState}
            locale={locales['ru']}
            dateDisplayFormat={'dd.MM.yyyy'}
            className="map-page_date-range"
          />
        </div>
      </div>
      <Map
        items={data ? data.logs : []}
        isLoading={isLoading}
        onMapClick={onMapClick}
      />
    </div>
  );
}