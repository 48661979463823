import { YMAPS_URL, YMAPS_API_KEY } from '../../constants/common';

export const getZoomControl = function (height) {
  return new window.ymaps.control.ZoomControl({
    options: {
      size: 'small',
      position: {
        top: height ? height / 2 - 30 : 250,
        bottom: 'auto',
        right: 10,
      },
    },
  });
};

const getFileName = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

const getBalloonContentBody = function (item) {
  let src = getFileName(item.country_flag);
  return `
    <div>
      <p>
        <img src="/flag?filename=${src}" width="32" height="16">
      </p>
      <p>Время: ${item.time}</p>
      <p>Путь: ${item.comment}</p>
      <p>Город: ${item.city}</p>
      <p>Страна: ${item.country_name}</p>
      <p>ISP: ${item.isp}</p>
    </div>
  `;
};

const getBalloonContentFooter = function (log) {
  return log.organization;
};

export function rerenderItems(params) {
  const { items, objectManager } = params;

  if (!objectManager) {
    return;
  }
  
  objectManager.removeAll();
  const objects = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.latitude && item.longitude) {
      objects.push({
        type: 'Feature',
        id: item.id,
        properties: {
          time: item.time,
          ip: item.ip,
          name: item.ip,
          hint: item.ip,
          comment: item.comment,
          country_name: item.country_name,
          state_prov: item.state_prov,
          city: item.city,
          country_flag: item.country_flag,
          isp: item.isp,
          organization: item.organization,
          time_zone_name: item.time_zone_name,
          time_zone_current_time: item.time_zone_current_time,
          time_zone_offset: item.time_zone_offset,
          balloonContentHeader: `<div>${item.ip}</div>`,
          balloonContentBody: getBalloonContentBody(item),
          balloonContentFooter: getBalloonContentFooter(item),
        },
        geometry: {
          type: 'Point',
          coordinates: [item.latitude, item.longitude],
        },
      });
    }
  }
  objectManager?.add(objects);
}

export function loadScript(id, url, location, onLoad) {
  if (!document.getElementById(id)) {
    const scriptTag = document.createElement('script');
    location.appendChild(scriptTag);
    scriptTag.src = url;
    scriptTag.id = id;
    if (onLoad) {
      scriptTag.onload = onLoad;
    }
  }
}

export function getYmapsUrl() {
  return `${YMAPS_URL}?apikey=${YMAPS_API_KEY}&lang=ru_RU`;
}