import React, { useState } from 'react';
import { TextInput } from '../../components/text-input/text-input';
import  './text-edit.css';

export function TextEdit() {
  return (
    <div className='text-edit_container'>
      <TextInput />
    </div>
  );  
}