import React from 'react';

function insertAt(string, index, substring) {
  if (index === undefined) {
    return;
  }
  return string.slice(0, index) + substring + string.slice(index);
}

// EXAMPLE
const textMap = {
  0: 'обычный текст, ',
  1: '<i>курсив</i>',
  2: ', ',
  3: '<b>жирный текст</b>',
  4: ', ',
  5: '<code>программный код</code>',
  6: ', и так далее.'
};

const allowedTags = ['b', 'i', 'code', 'sup', 'sub', 'q', 'span'];
const tagComponents = {
  b: 'b',
  code: 'code',
  i: 'i',
  sup: 'sup',
  sub: 'sub',
  q: 'q',
  span: 'span'
};

const CURSOR = '<span data-id="cursor"></span>';

function getTagName(text) {
  if (text.indexOf(' ') !== -1) {
    return text.slice(0, text.indexOf(' '));
  } else {
    return text;
  } 
}

function getDataIdValue(text) {
  const regex = /.*data-id="(.+?)"/;
  const found = text.match(regex);
  if (found) {
    return found[1];
  }
  return null;
}

export function removeCursor(originalString) {
  const regex = new RegExp(CURSOR, 'g');
  return originalString.replace(regex, '');
}

export function splitString(originalString, index) {
  return [
    originalString.slice(0, index),
    originalString.slice(index)
  ];
}

export function getSelectionDirection(selection) {
  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);
  let backward = false;
  // position == 0 if nodes are the same
  if ((!position && selection.anchorOffset > selection.focusOffset) || position === Node.DOCUMENT_POSITION_PRECEDING) {
      backward = true;
  }

  return backward;
}

export function getFormattedTextArray(text, cursorPosition, isBackward) {
  const elements = [];
  // const t = insertAt(text, cursorPosition, '<cursor>');
  const t = removeCursor(text);
  let index = 0;
  let key = 0;

  while (index < t.length) {
    const openIndex = t.indexOf("<", index); // ищем открывающую скобку
    if (openIndex === -1) {                  // такая скобка не найдена
      elements.push(<span key={key} data-id={key}>{t.slice(index)}</span>);
      break;
    } 
    if (openIndex > index) { // открывающая скобка найдена - копируем текст до её начала
      elements.push(<span key={key} data-id={key}>{t.slice(index, openIndex)}</span>);
      key++;
    }
    const closeIndex = t.indexOf(">", openIndex); // ищем закрывающую скобку
    if (closeIndex === -1) {                      // такая скобка не найдена
      elements.push(<span key={key} data-id={key}>{t.slice(index)}</span>);
      break;
    }
    const tagBig = t.slice(openIndex + 1, closeIndex);
    const tag = getTagName(tagBig);
    const dataId = getDataIdValue(tagBig);
    let pos;
    pos = t.indexOf(`</${tag}>`, closeIndex); // ищем закрывающий тэг
    if (pos === -1) {       // закрывающий тэг не найден
      elements.push(<span key={key} data-id={key}>{t.slice(index)}</span>);
      break;
    }
    const content = t.slice(closeIndex + 1, pos);
    if (tagComponents[tag]) {
      const Tag = tagComponents[tag];
      // if (cursorPosition && dataId === cursorPosition.endKey) {
      //   if (cursorPosition.endOffset !== content.length) {
      //     const n = isBackward ? cursorPosition.startOffset : cursorPosition.endOffset;
      //     const splitted = splitString(content, n);
      //     elements.push(<Tag key={key} data-id={key}>{splitted[0]}</Tag>);
      //     elements.push(<span key={`cursor`} data-id="cursor"></span>);
      //     key++;
      //     elements.push(<Tag key={key} data-id={key}>{splitted[1]}</Tag>);
      //   } else {
      //     elements.push(<Tag key={key} data-id={key}>{content}</Tag>);
      //     elements.push(<span key={`cursor`} data-id="cursor"></span>);
      //   }
      // } else {
        elements.push(<Tag key={key} data-id={key}>{content}</Tag>);
      // }
    }
    index = pos + (`</${tag}>`.length);
    key++;
  }
  return <>{elements}</>;
}

// TODO по data-id можно понять кто поставил <> - пользователь или программа


// export default TextFormatter;