import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useDebounceWindowResize = (callback, debounceWaitMs = 200) => {
  const handleResize = useDebouncedCallback(callback, debounceWaitMs);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);
};
