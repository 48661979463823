import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { parse, isValid, differenceInHours } from 'date-fns'
import { nanoid } from 'nanoid';
import { API_BASE_URL } from '../../constants/path';
import { DATE_TIME_FORMAT } from '../../constants/common';

export function useSessionTimer() {
  const location = useLocation();
  const sessionId = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setError(false);
      const response = await fetch(`${API_BASE_URL}/tick/${sessionId.current}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          pathname: location.pathname, 
        })
      });
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  }

  const readTime = () => {
    return window.localStorage.getItem('lastDateTime');
  }
  
  const saveCurrentTime = () => {
    window.localStorage.setItem('lastDateTime', new Date().toISOString());
  }

  useEffect(() => {
    let id = window.localStorage.getItem('sessionId');
    let difference = 0;
    const lastDateTimeString = readTime();
    const parsed = parse(lastDateTimeString, DATE_TIME_FORMAT, new Date());
    if (lastDateTimeString && isValid(parsed)) {
      const now = parse(new Date().toISOString(), DATE_TIME_FORMAT, new Date())
      difference = differenceInHours(now, parsed);
    } else {
      saveCurrentTime();
    }
    
    if (id && id.length === 21 && difference <= 1) {
      sessionId.current = id;
    } else {
      id = nanoid();
      sessionId.current = id;
      window.localStorage.setItem('sessionId', id);
      saveCurrentTime();
    }

    const interval = setInterval(() => {
      fetchData();
      saveCurrentTime();
    }, 5000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return [data, error];
}