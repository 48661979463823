import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import TextFormatter, { getTextMap, getFormattedTextArray, getSelectionDirection, removeCursor } from './text-formatter';
import  './text-input.css';

const example = '<span data-id="0">обычный текст, </span><i data-id="1">курсив</i><span data-id="2">, </span><b data-id="3">жирный текст</b><span data-id="4">, </span><code data-id="5">программный код</code><span data-id="6">, и так далее.</span>';
const example2 = 'обычный текст, <i>курсив</i>, <b>жирный текст</b>, <code>программный код</code>, и так далее.';

const wrapSelectionWithTag = (tag) => {

  const selection = window.getSelection();
  const selectedText = selection.toString();
  const anchorNode = selection.anchorNode;
  const focusNode = selection.focusNode;
  const anchorOffset = selection.anchorOffset;
  const focusOffset = selection.focusOffset;
  
  if (selectedText) {
    const range = selection.getRangeAt(0);
    const newElement = document.createElement(tag);
  
    if (range) {
      range.deleteContents();
    
      if(anchorNode === focusNode) {
        const newNode = anchorNode.splitText(focusOffset);
        newNode.splitText(anchorOffset);
    
        newNode.parentNode.replaceChild(newElement, newNode);
        newElement.appendChild(newNode);
        newNode.parentNode.insertBefore(newElement, newNode);
      } else {
        const startNode = anchorNode.splitText(anchorOffset);
        const endNode = focusNode.splitText(focusOffset);
    
        const nodes = [];
        let currentNode = startNode.nextSibling;
    
        while (currentNode !== endNode) {
          nodes.push(currentNode);
          currentNode = currentNode.nextSibling;
        }
    
        for (let i = 0; i < nodes.length; i++) {
          newElement.appendChild(nodes[i]);
        }
    
        startNode.parentNode.replaceChild(newElement, startNode);
        endNode.parentNode.replaceChild(newElement.cloneNode(true), endNode);
      }
    }
  }
}

export function TextInput({ isDisabled}) {
  const [isFocused, setIsFocused] = useState(false);
  const [header, setHeader] = useState('Заголовок');
  const [body, setBody] = useState(example);
  const [cursorPosition, setCursorPosition] = useState({
    header: null,
    body: null,
  });
  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const isMouseDown = useRef(false);
  const isBackwardSelection = useRef(false);

  const onChangeHeader = (text) => {
    console.log('text:', text);
    setHeader(text);
  };
  
  const onChangeBody = (event) => {
    console.log('key:', event.key);
    // setBody(text);
  };

  const onMouseUp = (element) => {
    isMouseDown.current = true;
    let selectedText = '';
    const selection = window.getSelection();
    console.log('selection:', selection);
    isBackwardSelection.current = getSelectionDirection(selection);
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (range) {
        selectedText = range.toString();
        console.log('selectedText:', selectedText);
        
        if (element === 'body') {
          setCursorPosition({
            header: null,
            body: {
              startKey: selection.anchorNode.parentNode.getAttribute('data-id'),
              startOffset: selection.anchorOffset,
              endKey: selection.focusNode.parentNode.getAttribute('data-id'),
              endOffset: selection.focusOffset,
            },
          })
        } else {
          setCursorPosition({
            header: null,
            body: null,
          })
        }
      }
    } else {
      setCursorPosition({
        header: null,
        body: null,
      })
    }
  }

  const onMouseDown = () => {
    isMouseDown.current = true;
    setBody((prevText) => {
      return removeCursor(prevText);
    });
  };

  const onLostFocus = (element) => {
    if (element === 'body') {
      setIsFocused(false);
    } else {
      
    }
  };

  console.log('cursorPosition:', cursorPosition?.body);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.innerHTML = example2;
      // bodyRef.current.insertAdjacentHTML('beforebegin', '<ol><li>html data</li></ol>');
    }
  }, []);

  return (
    <div className='text-input_container' onKeyUp={onChangeBody}>
      <div
        tabIndex="0"
        className='text-input_header'
        onKeyUp={onChangeHeader}
        onMouseUp={() => onMouseUp('header')}
        ref={headerRef}
      >
      </div>
      <div
        tabIndex="1"
        contentEditable="true"
        className='text-input_body'
        onKeyUp={onChangeBody}
        onMouseUp={() => onMouseUp('body')}
        onMouseDown={() => onMouseDown('body')}
        onFocus={() => setIsFocused(true)}
        onBlur={() => onLostFocus('body')}
        ref={bodyRef}
      >        
      </div>
      <p>isFocused: {isFocused.toString()}</p>
    </div>
  );  
}