import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDebounceWindowResize } from '../hooks/use-debounce-window-resize';
import { Spinner }  from '../spinner/spinner';
import './map.style.css';
import {
  rerenderItems,
  getZoomControl,
  loadScript,
  getYmapsUrl,
} from './map.utils';

export const Map = ({
  items,
  isLoading,
  onMapClick,
}) => {
  const containerRef = useRef(null);
  const mapInstance = useRef(null);
  const zoomControlInstance = useRef(null);
  const objectManager = useRef(null);
  const itemsRef = useRef(null);

  const handleResize = () => {
    if (mapInstance.current && mapInstance.current.container) {
      mapInstance.current.container.fitToViewport();
    }
  };

  useDebounceWindowResize(handleResize);

  const onYmapsReady = () => {
    zoomControlInstance.current = getZoomControl();
    mapInstance.current = new window.ymaps.Map(
      'yandex-map',
      {
        center: [55.76, 37.64],
        zoom: 5,
        controls: [zoomControlInstance.current],
      },
      {
        suppressMapOpenBlock: true,
        minZoom: 3,
      },
    );

    objectManager.current = new window.ymaps.ObjectManager({
      clusterize: true,
      gridSize: 32,
      clusterDisableClickZoom: true,
    });
    mapInstance.current.geoObjects.add(objectManager.current);
    if (itemsRef.current && itemsRef.current.length) {
      rerenderItems({
        items: itemsRef.current,
        objectManager: objectManager.current,
      });
    }

    mapInstance.current.events.add('mousedown', function() {
      onMapClick && onMapClick();
      objectManager.current?.objects?.balloon?.close();
      objectManager.current?.clusters?.balloon?.close();
    });
  };

  // Инициализация карты
  useEffect(() => {
    if (window.ymaps && window.ymaps.ready) {
      window.ymaps.ready(onYmapsReady);
    } else if (!window.ymaps) {
      const url = getYmapsUrl();
      loadScript('ymaps', url, document.head, () => {
        window.ymaps.ready(onYmapsReady);
      });
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.destroy();
      }
    };
  }, []);

  // Изменились данные
  useEffect(() => {
    if (items) {
      itemsRef.current = items;
      rerenderItems({
        items: items,
        objectManager: objectManager.current,
      });
    }
  }, [items]);

  // Изменился размер окна
  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!mapInstance.current) return;
      const elementRect = containerRef.current.getBoundingClientRect();
      const newZoomControl = getZoomControl(elementRect.height);
      if (zoomControlInstance.current) {
        mapInstance.current.controls?.remove(zoomControlInstance.current);
      }
      mapInstance.current.controls?.add(newZoomControl);
      zoomControlInstance.current = newZoomControl;
    });
    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className={'map-container'} ref={containerRef}>
      <div id='yandex-map' className={'map'}></div>
      { isLoading && (
        <div className={cn('overlay-container', 'static-container')}>
          <Spinner className={'map-spinner'} />
        </div>
      )}
    </div>
  );
};
