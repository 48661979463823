import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSessionTimer } from '../../components/hooks/use-session-timer';
import './main.css';

export function Main() {
  const [data, error] = useSessionTimer();

  const getVersion = async () => {
    try {
      const response = await fetch('/v');
      const textData = await response.text();
      console.log('v:', textData);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <div className="main">
      <h1 className='header'>Hello world!</h1>
  
      <p>
        Этот сайт сделан на коленке за 10 минут.
        По всем вопросам пишите на почту hello@globad.ru
      </p>

      <div className="links">
        <Link to={'/draw'} >Рисовалка</Link>
        <Link to={'/map'} >Карта</Link>
      </div>

      <div className="timer-info">
        {error && <p>{error.message}</p>}
        {data && <p>{`tick: ${data?.tick}`}</p>}
        {data && <p>{`duration: ${data?.duration_n}`}</p>}
      </div>
    </div>
  );
}