import React, { useState, useEffect, cloneElement } from "react";
import { CanvasProvider } from "./canvas-context";
import { Canvas } from "./canvas";
import { DrawingToolsPanel } from "./drawing-tools";
import { useSessionTimer } from '../../components/hooks/use-session-timer';
import "./draw-app-styles.css";

export function DrawApp() {
  const [data, error] = useSessionTimer();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    const w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    setWidth(w);
    setHeight(h);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <CanvasProvider>
      <Canvas />
      <DrawingToolsPanel screenWidth={width} />
    </CanvasProvider>
  );
}

{/*
<div className="debug-info">
  width: {width} <br />
  height: {height} <br />
  screen width: {window.screen.width} <br />
  screen height: {window.screen.height} <br />
</div>
*/}